<template>
    <div class="d-flex flex-column align-center">
        <product-banner>Add More Campaigns</product-banner>
        <product-card id="1010">
            <template #title>1 Microsoft MSN Publishing Distribution</template>
            <template #description>
                Post on Microsoft's MSN.com and tap into over 8+ billion monthly
                organic users from the Microsoft Traffic Ecosystem; with
                preferred ranking in Bing, Bing News and Yahoo too.
            </template>
        </product-card>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { ProductBanner, ProductCard } from '@/components/ProductCard';
@Component({
    components: {
        ProductBanner,
        ProductCard
    }
})
export default class MSN extends Vue {
    mounted() {
        this.$emit(
            'appointment',
            'https://try.ampifire.com/meetings/jbulzak/microsoft-msn-publishing'
        );
    }
}
</script>
